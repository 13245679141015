import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

import { Customers_Address_AddressModel } from '@monorepo-types/dc';

interface AddressFinderProviderProps {
  children: ReactNode;
}

const AddressFinderStateContext = createContext<
  | {
      addressData: Customers_Address_AddressModel;
      setAddressData: Dispatch<SetStateAction<Customers_Address_AddressModel>>;
    }
  | undefined
>(undefined);

const AddressFinderProvider = ({ children }: AddressFinderProviderProps) => {
  const [addressData, setAddressData] = useState<Customers_Address_AddressModel>({});

  return (
    <AddressFinderStateContext.Provider
      value={{
        addressData,
        setAddressData,
      }}>
      {children}
    </AddressFinderStateContext.Provider>
  );
};

const useAddressFinder = () => {
  const context = useContext(AddressFinderStateContext);
  if (!context) throw new Error('useAddressFinder must be used within a AddressFinderProvider');
  return context;
};

export { AddressFinderProvider, useAddressFinder };
