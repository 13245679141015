import { createDCHook, collapseDataFromCall } from '../client';
import { getAddressPrivate, getAddressPrivateV2 } from '../services/AddressService';

export const useAddressGetAddressPrivate = createDCHook('getAddressPrivate', collapseDataFromCall(getAddressPrivate), {
  injectables: ['label', 'customerId', 'businessUnit'],
  flattenData: true,
});

export const useAddressGetAddressPrivateV2 = createDCHook(
  'getAddressPrivateV2',
  collapseDataFromCall(getAddressPrivateV2),
  { injectables: ['label', 'customerId', 'businessUnit'], flattenData: true },
);
