import { ComponentProps, useEffect } from 'react';

import { Customers_Address_AddressModel } from '@monorepo-types/dc';

import { AddressFinderPublic } from '../AddressFinderPublic';
import { Error } from '../AddressFinderView';

interface Suffix {
  label: string;
  value: string;
}

interface Props
  extends Pick<
    ComponentProps<typeof AddressFinderPublic>,
    'emptySuffixString' | 'currentSelectedHouseNumberSuffix' | 'resetField' | 'setIsSuffixRequired'
  > {
  data: Customers_Address_AddressModel | undefined;
  error: Error;
  houseNumberSuffixOptions: Suffix[];
}

export const useHouseNumberSuffixValidation = ({
  currentSelectedHouseNumberSuffix,
  data,
  emptySuffixString,
  error,
  houseNumberSuffixOptions,
  resetField,
  setIsSuffixRequired,
}: Props): void => {
  useEffect(() => {
    // when address api did not respond yet, skip houseNumberSuffix reset.
    if (!data && !error) return;
    const defaultValue = currentSelectedHouseNumberSuffix || '';
    resetField('houseNumberSuffix', { defaultValue });
  }, [currentSelectedHouseNumberSuffix, data, error, resetField]);

  useEffect(() => {
    if (!data || !currentSelectedHouseNumberSuffix) return;
    const hasSelectedSuffix = houseNumberSuffixOptions.some(
      suffix => suffix.value === currentSelectedHouseNumberSuffix,
    );
    if (!hasSelectedSuffix) {
      resetField('houseNumberSuffix', { defaultValue: undefined });
    }
  }, [currentSelectedHouseNumberSuffix, data, houseNumberSuffixOptions, resetField]);

  useEffect(() => {
    const isSuffixRequired =
      houseNumberSuffixOptions.length > 0 &&
      !houseNumberSuffixOptions.some(suffix => suffix.value === emptySuffixString);
    setIsSuffixRequired(isSuffixRequired);
  }, [emptySuffixString, houseNumberSuffixOptions, setIsSuffixRequired]);
};
