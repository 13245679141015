import { useEffect } from 'react';

import { Customers_Address_AddressModel } from '@monorepo-types/dc';

import { useAddressFinder } from '../AddressFinderContext';

export const useAddressDataToContext = (
  data: Customers_Address_AddressModel | undefined,
  houseNumberSuffix?: Customers_Address_AddressModel['houseNumberSuffix'],
) => {
  const { addressData, setAddressData } = useAddressFinder();

  useEffect(() => {
    if (data) {
      setAddressData({ ...data, houseNumberSuffix });
    } else {
      setAddressData({});
    }
  }, [data, houseNumberSuffix, setAddressData]);

  return { addressData };
};
