import { createDCHook, collapseDataFromCall } from '../client';
import { getAddressPublic, getAddressPublicV2, getAddressBusinessCheck } from '../services/AddressPublicService';

export const useAddressPublicGetAddressPublic = createDCHook(
  'getAddressPublic',
  collapseDataFromCall(getAddressPublic),
  { injectables: ['label', 'businessUnit'], flattenData: true },
);

export const useAddressPublicGetAddressPublicV2 = createDCHook(
  'getAddressPublicV2',
  collapseDataFromCall(getAddressPublicV2),
  { injectables: ['label', 'businessUnit'], flattenData: true },
);

export const useAddressPublicGetAddressBusinessCheck = createDCHook(
  'getAddressBusinessCheck',
  collapseDataFromCall(getAddressBusinessCheck),
  { injectables: ['label', 'businessUnit'], flattenData: true },
);
