import { ComponentProps, FC, useEffect } from 'react';

import { useAddressPrivateApi } from '@hooks/address';

import { AddressFinderPublic } from './AddressFinderPublic';
import { AddressFinderView } from './AddressFinderView';
import { useAddressDataToContext } from './hooks/useAddressDataToContext';
import { useHouseNumberSuffixValidation } from './hooks/useHouseNumberSuffixValidation';

export const AddressFinderPrivate: FC<
  ComponentProps<typeof AddressFinderPublic> &
    Pick<ComponentProps<typeof AddressFinderView>, 'customLabels' | 'isSuffixRequired' | 'hasAutocomplete'>
> = ({
  currentSelectedHouseNumberSuffix,
  customLabels,
  emptySuffixString,
  isSuffixRequired,
  parameters,
  resetField,
  setIsSuffixRequired,
  setErrorData,
  setIsLoading,
  customErrorNotification,
  hasAutocomplete,
}) => {
  const { data, error, houseNumberSuffixOptions, isValidating } = useAddressPrivateApi(parameters);
  const { addressData } = useAddressDataToContext(data, currentSelectedHouseNumberSuffix);

  useHouseNumberSuffixValidation({
    currentSelectedHouseNumberSuffix,
    data,
    emptySuffixString,
    error,
    houseNumberSuffixOptions,
    resetField,
    setIsSuffixRequired,
  });

  useEffect(() => {
    setIsLoading?.(isValidating);
  }, [isValidating, setIsLoading]);

  return (
    <AddressFinderView
      addressData={addressData}
      apiError={error}
      currentSelectedHouseNumberSuffix={currentSelectedHouseNumberSuffix}
      customLabels={customLabels}
      houseNumberSuffixOptions={houseNumberSuffixOptions}
      isSuffixRequired={isSuffixRequired}
      isValidating={isValidating}
      setErrorData={setErrorData}
      customErrorNotification={customErrorNotification}
      hasAutocomplete={hasAutocomplete}
      resetField={resetField}
    />
  );
};
