import { ComponentProps, Dispatch, FC, SetStateAction, useEffect } from 'react';

import { FieldValues, UseFormResetField } from 'react-hook-form';

import { ApiError } from '@dc/client/ApiError';
import { useAddressPublicApi } from '@hooks/address';

import { AddressFinderView } from './AddressFinderView';
import { useAddressDataToContext } from './hooks/useAddressDataToContext';
import { useHouseNumberSuffixValidation } from './hooks/useHouseNumberSuffixValidation';

interface ApiLayerProps {
  emptySuffixString: string | undefined;
  currentSelectedHouseNumberSuffix?: string;
  parameters: {
    houseNumber: string;
    houseNumberSuffix: string;
    postalCode: string;
  } | null;
  resetField: UseFormResetField<FieldValues>;
  setIsSuffixRequired: Dispatch<SetStateAction<boolean>>;
  setErrorData?: Dispatch<SetStateAction<ApiError | undefined>>;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
}

export const AddressFinderPublic: FC<
  ApiLayerProps &
    Pick<ComponentProps<typeof AddressFinderView>, 'customLabels' | 'isSuffixRequired' | 'hasAutocomplete'> & {
      customErrorNotification?: React.ReactElement;
    }
> = ({
  currentSelectedHouseNumberSuffix,
  customLabels,
  emptySuffixString,
  isSuffixRequired,
  parameters,
  resetField,
  setIsSuffixRequired,
  setErrorData,
  setIsLoading,
  customErrorNotification,
  hasAutocomplete,
}) => {
  const { data, error, houseNumberSuffixOptions, isValidating } = useAddressPublicApi(parameters);
  const { addressData } = useAddressDataToContext(data, currentSelectedHouseNumberSuffix);

  useHouseNumberSuffixValidation({
    currentSelectedHouseNumberSuffix,
    data,
    emptySuffixString,
    error,
    houseNumberSuffixOptions,
    resetField,
    setIsSuffixRequired,
  });

  useEffect(() => {
    setIsLoading?.(isValidating);
  }, [isValidating, setIsLoading]);

  return (
    <AddressFinderView
      addressData={addressData}
      apiError={error}
      currentSelectedHouseNumberSuffix={currentSelectedHouseNumberSuffix}
      customLabels={customLabels}
      houseNumberSuffixOptions={houseNumberSuffixOptions}
      isSuffixRequired={isSuffixRequired}
      isValidating={isValidating}
      setErrorData={setErrorData}
      customErrorNotification={customErrorNotification}
      hasAutocomplete={hasAutocomplete}
      resetField={resetField}
    />
  );
};
